import React from 'react';
import styled from 'styled-components';
import SEO from 'components/shared/SEO';
import Content from 'components/shared/Content';

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  line-height: 1.5;

  ${({ theme }) => theme.mq.xxxl} {
    padding-top: 160px;
  }
`;

const StyledContent = styled(Content)`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 60px;
  padding: 0 20px;

  ${({ theme }) => theme.mq.lg} {
    max-width: 1100px;
    padding: 0 60px;
  }
`;

const Header = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.lgm};
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 80px;
    background: rgb(112, 112, 112);
    margin: 30px auto 35px;
  }
`;

const Wpis = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: ${({ theme }) => theme.fontSize.m};

  p,
  a,
  div,
  img {
    max-width: 100%;
  }

  a {
    display: block;
    word-wrap: break-word;
  }
`;

const VideoYT = styled.div`
  width: 100%;
  max-width: 480px;
  height: 240px;
  padding: 0 20px;
  margin-bottom: 80px;

  ${({ theme }) => theme.mq.s} {
    padding: 0;
    height: 340px;
  }

  ${({ theme }) => theme.mq.lg} {
    max-width: 980px;
    height: 500px;
  }
`;

const BlogPostLayout = ({ pageContext: { blogArticle } }) => {
  return (
    <Main>
      <SEO title={blogArticle.title} />
      <StyledContent>
        <Header>{blogArticle.title}</Header>
        <Wpis dangerouslySetInnerHTML={{ __html: blogArticle.blog.wpis }} />
      </StyledContent>

      {blogArticle.blog.yt && (
        <VideoYT>
          <iframe
            width="100%"
            height="100%"
            src={blogArticle.blog.yt}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </VideoYT>
      )}
      {/* <ReadMore>
        <Header as="h6">Czytaj więcej</Header>
      </ReadMore> */}
    </Main>
  );
};

export default BlogPostLayout;
